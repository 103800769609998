import './src/scss/index.scss';

export const onRouteUpdate = () => {
    document.getElementsByTagName('body')[0]?.classList.remove('nav-expanded');
    document.getElementsByTagName('body')[0]?.classList.remove('sub-nav-expanded');

	 const scrollToTopRoutes = ['/latest']
	 const { pathname } = window.location
 
	 if (scrollToTopRoutes.some(route => pathname.includes(route))) {
		 setTimeout(() => {
			 window.scrollTo({
				 top: 0,
				 behavior: 'instant'
			 })
		 }, 1)
	 }
}

export const shouldUpdateScroll = () => {
    return [0, 0]
}